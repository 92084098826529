var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 40 40",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M37 11.7H21.7V3C21.7 1.4 20.4 0 18.7 0H3C1.3 0 0 1.4 0 3V37C0 38.7 1.3 40 3 40H37C38.7 40 40 38.7 40 37V14.7C40 13 38.7 11.7 37 11.7ZM6.3 38V32.3C6.3 31.8 6.7 31.3 7.3 31.3H9.8V38H6.3ZM15.3 38H11.8V31.3H14.3C14.9 31.3 15.3 31.8 15.3 32.3V38ZM19.7 38H17.4V32.3C17.4 30.6 16.1 29.3 14.4 29.3H7.4C5.7 29.3 4.4 30.6 4.4 32.3V38H3C2.5 38 2 37.6 2 37V3C2 2.4 2.5 2 3 2H18.7C19.2 2 19.7 2.4 19.7 3V38ZM38 37C38 37.6 37.5 38 37 38H21.7V13.7H37C37.5 13.7 38 14.2 38 14.7V37Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.2998 6.80078H5.2998C4.6998 6.80078 4.2998 6.40078 4.2998 5.80078C4.2998 5.20078 4.6998 4.80078 5.2998 4.80078H16.2998C16.8998 4.80078 17.2998 5.20078 17.2998 5.80078C17.2998 6.40078 16.8998 6.80078 16.2998 6.80078Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.2998 11.8008H5.2998C4.6998 11.8008 4.2998 11.4008 4.2998 10.8008C4.2998 10.2008 4.6998 9.80078 5.2998 9.80078H16.2998C16.8998 9.80078 17.2998 10.2008 17.2998 10.8008C17.2998 11.4008 16.8998 11.8008 16.2998 11.8008Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.2998 16.8008H5.2998C4.6998 16.8008 4.2998 16.4008 4.2998 15.8008C4.2998 15.2008 4.6998 14.8008 5.2998 14.8008H16.2998C16.8998 14.8008 17.2998 15.2008 17.2998 15.8008C17.2998 16.4008 16.8998 16.8008 16.2998 16.8008Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M16.2998 21.8008H5.2998C4.6998 21.8008 4.2998 21.4008 4.2998 20.8008C4.2998 20.2008 4.6998 19.8008 5.2998 19.8008H16.2998C16.8998 19.8008 17.2998 20.2008 17.2998 20.8008C17.2998 21.4008 16.8998 21.8008 16.2998 21.8008Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.2998 18.8008H24.2998C23.6998 18.8008 23.2998 18.4008 23.2998 17.8008C23.2998 17.2008 23.6998 16.8008 24.2998 16.8008H35.2998C35.8998 16.8008 36.2998 17.2008 36.2998 17.8008C36.2998 18.4008 35.8998 18.8008 35.2998 18.8008Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.2998 23.8008H24.2998C23.6998 23.8008 23.2998 23.4008 23.2998 22.8008C23.2998 22.2008 23.6998 21.8008 24.2998 21.8008H35.2998C35.8998 21.8008 36.2998 22.2008 36.2998 22.8008C36.2998 23.4008 35.8998 23.8008 35.2998 23.8008Z",
          fill: _vm.color,
        },
      }),
      _c("path", {
        attrs: {
          d: "M35.2998 28.8008H24.2998C23.6998 28.8008 23.2998 28.4008 23.2998 27.8008C23.2998 27.2008 23.6998 26.8008 24.2998 26.8008H35.2998C35.8998 26.8008 36.2998 27.2008 36.2998 27.8008C36.2998 28.4008 35.8998 28.8008 35.2998 28.8008Z",
          fill: _vm.color,
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }